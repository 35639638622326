import { event as gTagEvent } from 'vue-gtag'
import { getFlags } from './feature-flags'
import Cookies from 'js-cookie'
import { getSessionUUID, getVisitorID, getCookieUUID } from './global'
import { useLandingPage } from '@/stores/landingPage'
import CONSTANTS from '@/helpers/constants'

const apiUrl = process.env.VUE_APP_ANALYTICS_API

function trigger(eventName, options) {
	const payload = {}
	payload.n = eventName
	payload.u = window.location.href
	payload.r = options.referrer || window.document.referrer || null
	payload.w = window.innerWidth
	if (options && options.meta) {
		payload.m = options.meta
	}

	if (!('m' in payload)) {
		payload.m = {}
	}
	payload.m.platform = {
		oscpu: navigator.oscpu,
		vendor: navigator.vendor,
		platform: navigator.platform,
		cpuClass: navigator.cpuClass,
		appName: navigator.appName,
		appVersion: navigator.appVersion,
		codeName: navigator.appCodeName,
		userAgent: navigator.userAgent,
	}

	if (options && options.props) {
		payload.p = options.props
	}

	const request = new XMLHttpRequest()
	request.open('POST', apiUrl, true)
	request.setRequestHeader('Content-Type', 'application/json')
	request.send(JSON.stringify(payload))

	const flattenOptions = { ...payload.m, ...payload.p }
	Object.keys(flattenOptions).forEach(v => {
		if (v.includes('-')) {
			flattenOptions[v.replace('-', '_')] = flattenOptions[v]
			delete flattenOptions[v]
		}
	})

	// google tracking
	const googleTagEventName = CONSTANTS.ANALYTICS_MAPPING[eventName] ?? eventName
	flattenOptions.send_to = CONSTANTS.GOOGLE_AD_CONVERSION_ID
	gTagEvent(googleTagEventName, flattenOptions)

	request.onreadystatechange = function () {
		if (request.readyState === 4) {
			options && options.callback && options.callback()
		}
	}
}
export const stanAnalytics = async (name, opts = {}) => {
	const meta = opts.meta || {}
	const props = opts.props || {}
	const referrer = opts.referrer
	// Plugin default metadata
	meta.sessionUUID = getSessionUUID()
	meta.cookieUUID = getCookieUUID()

	// May or may not be used
	meta.visitorId = await getVisitorID()

	props.experiments = getFlags()
	const landingPage = useLandingPage()

	if (landingPage.forceExperiment) {
		delete props.experiments['landing-page-v6-vs-v8']
	}

	const utmSource = Cookies.get('utm_source')
	const utmMedium = Cookies.get('utm_medium')
	const utmCampaign = Cookies.get('utm_campaign')

	if (utmSource) {
		props.utmSource = utmSource
	}
	if (utmMedium) {
		props.utmMedium = utmMedium
	}
	if (utmCampaign) {
		props.utmCampaign = utmCampaign
	}

	// Disabled Fingerprint until figure out the payment and plan details
	// meta.visitorId = await getVisitorID()
	// eslint-disable-next-line no-undef
	return trigger(name, { meta, props, referrer })
}
