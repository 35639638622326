const CONSTANTS = {
	ANALYTICS_MAPPING: {
		'landing-registration-trigger': 'sign_up',
		'landing-signin-trigger': 'login',
		fcp: 'fcp',
		'landing-page-view': 'ad_impression',
		'landing-play-demo': 'select_content',
		'landing-video-playing': 'select_content',
		'landing-watch-demo-play': 'select_content',
		'referral-link-landing': 'referral_link_landing',
	},
	AD_CONTENT: {
		podcast: {
			campaign: {
				defaultStanCampaign: {
					pageContent: {
						heroHeader: 'Your All·in·One Podcast Store',
						heroSubHeader: 'The easiest way for podcasters to grow, monetize, and engage their audience — all from your link-in-bio.',
						heroImage: '/images/home/ad-campaign/hero-podcast.png',
						replaceHero: true,
					},
				},
			},
		},
		real_estate: {
			campaign: {
				defaultStanCampaign: {
						pageContent: {
							referrerFullName: 'Jenna',
							heroHeader: 'Turn your Real Estate Expertise into Income.',
							heroSubHeader: 'Grow your real estate coaching business with tools that help you market, sell, and expand — all in one place.',
							referralComment:
								// eslint-disable-next-line
								"“I remember logging into Stan Store and realizing, ‘This does everything.’ It collects payments, automates follow-ups, and keeps everything in one place—streamlining my entire business. I’ve now generated over $2M on Stan teaching other agents how to use Instagram.”",
							headshot: '/images/home/ad-campaign/janna-headshot.png',
							heroImage: '/images/home/ad-campaign/hero-janna.png',
							// eslint-disable-next-line
							stanXcreatorHeader: "Grow your income as a Real Estate 🏠 Creator",
							stanXcreatorSubheader:
								// eslint-disable-next-line
								"Whether you're selling homes or coaching top agents, Stan is your all-in-one marketing and sales platform helps you attract clients, grow your brand, and maximize sales.",
							featureSectionHeading: 'All Your Real Estate Tools in One Platform',
							featureSectionSubHeading: 'Stan has everything you need to market and grow your Real Estate business. All-in-one place.',
							customProductHighlightsAsset: 'images/home/ad-campaign/real-estate-features-image.png',
							socials: '@jennapeterson  |  stan.store/jennapeterson'
						},
					
				},
			},
		},
		dm_automation: {
			campaign: {
				defaultStanCampaign: {
					pageContent: {
						replaceHero: true,
						heroImage: '/images/home/ad-campaign/hero-auto-dm.png',
						heroHeader: 'Automate your DM Responses ',
						heroSubHeader:
							'Say goodbye to manual messaging with Stan AutoDM! Integrate with your Instagram to instantly respond to comments and DMs. What will you do with all that free time?!',

						customSetupSteps: {
							steps: [
								{
									header: 'Respond to every message, instantly',
									description:
										'Let Stan AutoDM take the wheel — put your DMs on autopilot and save hours with instant, personalized responses.',
									asset: '/images/home/ad-campaign/RespondtoEveryMessage.json',
									class: 'first',
								},
								{
									header: 'Turn DMs into 💰',
									description:
										'Never miss a comment or DM again — Stan AutoDM converts messages into sales by responding instantly and driving customers to your store.',
									asset: '/images/home/ad-campaign/TURNDMSINTOMONEY_MAN.json',
									class: 'right',
								},
								{
									header: 'Insights behind every message',
									description: 'Our analytics track everything from opens to conversions, helping you level up your strategy.',
									asset: '/images/home/ad-campaign/Insightsbehind.json',
									class: 'last',
								},
							],
						},
						customProductHighlightsAsset: '/images/home/ad-campaign/product-highlights-auto-dm.png',
						customProductPerksAsset: '/images/home/ad-campaign/pricing-card-auto-dm.png',
						removeTestimonials: true,
						removeShowcase: true,
						customReferralList: {
							header: 'Real Creators, Real Success',
							subheader: 'Get inspired by creators sharing how Stan AutoDM is transforming their DM game.',
							cards: [
								{
									image: '/images/home/ad-campaign/referral-list-auto-dm-taralouise.png',
									description: 'It’s saving me so much time and I’ve noticed a lot more clicks to my Stan Store',
									header: '@taralouisesmartsavings',
									subheader: '114K+ Followers',
								},
								{
									image: '/images/home/ad-campaign/referral-list-auto-dm-profitable.png',
									description: 'So simple to set up and has allowed me to cancel another monthly subscription!',
									header: '@profitable.pioneers',
									subheader: '19K+ Followers',
								},
								{
									image: '/images/home/ad-campaign/referral-list-auto-dm-lauren.png',
									description:
										'I love being able to see how many people got my message and how many people actually opened the link with it!',
									header: '@lauren_krueger',
									subheader: '615K+ Followers',
								},
								{
									image: '/images/home/ad-campaign/referral-list-auto-dm-amber.png',
									description: 'Before Stan AutoDM, I was sending DM responses one by one! The struggle!',
									header: '@ambersharniece',
									subheader: '21K+ Followers',
								},
								{
									image: '/images/home/ad-campaign/referral-list-auto-dm-musicalmom.png',
									description:
										'I love having the ability to link it to my products. It was simple to setup & I don’t have to pay for a separate service now!',
									header: '@themusicalmom',
									subheader: '700+ Followers',
								},
								{
									image: '/images/home/ad-campaign/referral-list-auto-dm-bossladies.png',
									description: 'DM Automation is so much easier with Stan so I’ve officially moved everything over to Stan!',
									header: '@bossladiesmindset',
									subheader: '786K+ Followers',
								},
								{
									image: '/images/home/ad-campaign/referral-list-auto-dm-meglouisa.png',
									description: 'Saves me time and makes my business more automated',
									header: '@MegLouisa',
									subheader: '17K+ Followers',
								},
							],
						},
					},
				},
				allinone: {
					heroHeader: null,
					heroSubHeader: null,
					replaceHero: false,
				}
			},
		},
		dm: {
			campaign: {
				defaultStanCampaign: {
					pageContent: {
						heroHeader: 'Turn IG Comments Into Leads',
						heroSubHeader:
							'Turn Instagram comments into instant sales or sign-ups! Stan’s Auto-DMs automatically reply to comments with personalized messages, exclusive offers, or direct links — driving traffic and revenue while you focus on creating.',
						heroImage: '/images/home/ad-campaign/hero-dm.png',
						replaceHero: true,
					},
				},
			},
		},
		community: {
			campaign: {
				defaultStanCampaign: {
					pageContent: {
						heroHeader: 'All·in·One Community',
						heroSubHeader:
							'Stan is the easiest way to build and engage your community online. Create a space where fans connect, interact, and stay up-to-date — all from your link-in-bio.',
						heroImage: '/images/home/ad-campaign/hero-community.png',
						replaceHero: true,
					},
				},
			},
		},
		affiliate: {
			campaign: {
				defaultStanCampaign: {
					pageContent: {
						heroHeader: 'Amplify Your Sales with Affiliate Share',
						heroSubHeader:
							'Turn your fans into ambassadors with Stan’s Affiliate Share! Allow your super fans to promote and sell your products, extending your reach and driving more sales.',
						heroImage: '/images/home/ad-campaign/hero-affiliate.png',
						replaceHero: true,
					},
				},
			},
		},
		'healthwellness-gtm': {
			campaign: {
				'all-in-one': {
					pageContent: {
						heroHeader: 'Build your fitness empire with Stan',
						heroSubHeader:
							'The easiest platform for health and wellness creators to grow, monetize, and connect with your audience. Offer digital workout plans, schedule coaching sessions, and build a thriving fitness community -  all from your link-in-bio.',
						heroImage: '/images/home/ad-campaign/hero-healthwellness-all-in-one.png',
						replaceHero: true,
					},
				},
				ecourse: {
					pageContent: {
						heroHeader: 'The Easiest Way to Monetize Your Fitness Content',
						heroSubHeader:
							'From eCourses and masterclasses, to workshops and more, Stan’s all-in-one platform makes it easy to share your expertise and empower your clients to reach their goals.',
						heroImage: '/images/home/ad-campaign/hero-healthwellness-all-in-one.png',
						replaceHero: true,
						listItemSection: {
							header: 'Why eCourses on Stan Are Perfect for You',
							listItems: [
								{
									header: 'Boost Engagement & Motivation',
									body: 'Guide your community through transformative workouts or wellness journeys, step-by-step, on your terms!',
									iconSrc: '/images/home/ad-campaign/icons/muscle.png',
								},
								{
									header: 'Generate Extra Income',
									body: 'Charge for courses, set up memberships, or offer tiered access for extra value',
									iconSrc: '/images/home/ad-campaign/icons/moneybag.png',
								},
								{
									header: 'Flexible & Creator-Friendly',
									body: 'Easily design and launch your eCourse with tools made just for creators like you',
									iconSrc: '/images/home/ad-campaign/icons/art.png',
								},
							],
						},
					},
				},
				digitaldownloads: {
					pageContent: {
						heroHeader: 'Sell your Digital Workouts with Ease',
						heroSubHeader:
							'From workout routines, meal plans, wellness guides and more, Stan’s all-in-one platform makes it easy to ensure your clients crush their goals.',
						heroImage: '/images/home/ad-campaign/hero-healthwellness-all-in-one.png',
						replaceHero: true,
						listItemSection: {
							header: 'Why Start Selling Digital Products on Stan?',
							listItems: [
								{
									header: 'Share Practical Resources',
									body: 'Offer meal plans, smoothie recipes, or fitness routines that your followers can start using right away',
									iconSrc: '/images/home/ad-campaign/icons/muscle.png',
								},
								{
									header: 'Engage Followers with Unique Content',
									body: 'Charge for courses, set up memberships, or offer tiered access for extra value',
									iconSrc: '/images/home/ad-campaign/icons/books.png',
								},
								{
									header: 'Quick & Easy Setup',
									body: 'Create, upload, and sell with no hassle — focus on what you love! ',
									iconSrc: '/images/home/ad-campaign/icons/rocket.png',
								},
							],
						},
					},
				},
				community: {
					pageContent: {
						heroHeader: 'Invite, engage, and connect with your wellness tribe',
						heroSubHeader:
							'From workout routines, meal plans, wellness guides and more, Stan’s all-in-one platform makes it easy to ensure your clients crush their goals.',
						heroImage: '/images/home/ad-campaign/hero-healthwellness-all-in-one.png',
						replaceHero: true,
						listItemSection: {
							header: 'Why Your Followers Deserve a Community',
							listItems: [
								{
									header: 'Stronger Connections',
									body: 'Host a space for meaningful conversations that foster growth and deeper engagement.',
									iconSrc: '/images/home/ad-campaign/icons/muscle.png',
								},
								{
									header: 'Exclusive Content',
									body: 'Offer more than just content—create memberships, workshops, and one-on-one coaching.',
									iconSrc: '/images/home/ad-campaign/icons/gem.png',
								},
								{
									header: 'Effortless Community Building',
									body: 'With easy-to-use tools for managing your community, you can focus on nurturing a supportive environment.',
									iconSrc: '/images/home/ad-campaign/icons/seedling.png',
								},
							],
						},
					},
				},
			},
		},
	},
	GOOGLE_AD_CONVERSION_ID: 'AW-10854378575',
	GOOGLE_GA4_MEASUREMENT_ID: 'G-FPXVPVPGVK',
	GOOGLE_TAG_MANAGER_ID: 'GTM-W9MXN8NB',

	GOOGLE_AD_CONV_ACTION: {
		STAN: {
			SIGN_UP: 'AW-10854378575/7wODCK-_u94ZEM_Y4rco',
		},
		STAN_V2: {
			SIGN_UP: 'AW-16870002766/egR3CLG-pZ0aEM6gn-w-',
		},
	},
	FAKE_PROFILES: {
		justinwelsh: {
			full_name: 'Justin Welsh',
			pageContent: {
				heroHeader: 'Your All-in-One Solopreneur Store',
				heroSubHeader: 'The Saturday Solopreneur community gets their first month free when joining Stan. Start your business today.',
				referralComment:
					// eslint-disable-next-line
					"“I've tested a lot of online platforms. Stan blows me away. It's by far the easiest, fastest, and most affordable choice for anyone starting out as a solopreneur.”",
				headshot: '/images/home/v6/justin-welsh-headshot.png',
				heroImage: '/images/home/v6/justin-welsh-phone-preview.png',
				// eslint-disable-next-line
				stanXcreatorHeader: "Exclusive ONLY to Justin's Community!",
				stanXcreatorSubheader:
					// eslint-disable-next-line
					"Justin is the only person who has a free 30 days trial when joining Stan. This is the only time you'll get this offer.",
				stanXcreatorImage: '/images/home/v6/stanXjustin.png',
			},
		},
		patflynn: {
			full_name: 'Pat Flynn',
			pageContent: {
				heroHeader: 'Your All·in·One Smart Passive Income Store',
				heroSubHeader: 'The SPI audience gets their first month free when joining Stan. Start your business today.',
				referralComment:
					// eslint-disable-next-line
					'“When it comes to setting up your brand and offerings online, Stan is the most impressive tool I’ve come across in years. Speed, ease of use, and an intuitive experience – Stan Store is definitely the one you’d want to start with.”',
				headshot: '/images/home/v6/pat-flynn-headshot.png',
				heroImage: '/images/home/v6/pat-flynn-hero.png',
				// eslint-disable-next-line
				stanXcreatorHeader: "Exclusive Offer for Pat Flynn's Audience!",
				stanXcreatorSubheader:
					// eslint-disable-next-line
					"Pat's audience gets 30 days free. Claim this limited offer now.",
				stanXcreatorImage: '/images/home/v6/stanXpat.png',
			},
		},
		chasedimond: {
			full_name: 'Chase Dimond',
			pageContent: {
				heroHeader: 'Meet Your All·in·One Creator Store',
				heroSubHeader:
					'Stan is the easiest way to make money online. All of your courses, digital products, and bookings are now hosted within your link-in-bio.',
				referralComment:
					// eslint-disable-next-line
					"“Stan truly gets what Creators need to build their business. I've used a lot of tools in my day, but now I use Stan. It's the only platform you need to get the job done. Hands down, best experience.”",
				headshot: '/images/home/v6/chase-headshot.png',
				heroImage: '/images/home/v6/chase-hero.png',
				// eslint-disable-next-line
				stanXcreatorHeader: "Exclusive Offer for Chase Dimond's Community!",
				stanXcreatorSubheader:
					// eslint-disable-next-line
					"Chase's community gets 30 days free. Claim this limited offer now.",
				stanXcreatorImage: '/images/home/v6/stanXchase.png',
			},
		},
		laraacosta: {
			full_name: 'Lara Acosta',
			pageContent: {
				heroHeader: 'Literally, Your All-in-One Creator Store',
				heroSubHeader:
					'Stan is the easiest way to make money online. All of your courses, digital products, and bookings are now hosted within your link-in-bio.',
				referralComment:
					// eslint-disable-next-line
					'“I made my first online dollar using Stan Store. It’s the simplest, fastest, and highest-converting solution out there, offering the best bang for your buck. If you’re serious about succeeding online, start with Stan Store.”',
				headshot: '/images/home/v6/lara-acosta-headshot.png',
				heroImage: '/images/home/v6/lara-acosta-hero.png',
				// eslint-disable-next-line
				stanXcreatorHeader: "Exclusive Offer for Lara Acosta's Community!",
				stanXcreatorSubheader:
					// eslint-disable-next-line
					"Laras's community gets 30 days free. Claim this limited offer now.",
				stanXcreatorImage: '/images/home/v6/stanXlara.png',
			},
		},
		dominiquebroadway: {
			full_name: 'Dominique Broadway',
			pageContent: {
				heroHeader: 'Literally, Your All-in-One Creator Store',
				heroSubHeader:
					'Stan is the easiest way to make money online. All of your courses, digital products, and bookings are now hosted within your link-in-bio.',
				referralComment:
					// eslint-disable-next-line
					"“Stan is the best solution I've used to build additional revenue streams that fuel a financially healthy foundation for my business. Excited for you to get started too!”",
				headshot: '/images/home/v6/dominique-broadway-headshot.png',
				heroImage: '/images/home/v6/dominique-broadway-hero.png',
				// eslint-disable-next-line
				stanXcreatorHeader: "Exclusive Offer for Dominique Broadway's Community!",
				stanXcreatorSubheader:
					// eslint-disable-next-line
					"Dominique's community gets 30 days free. Claim this limited offer now.",
				stanXcreatorImage: '/images/home/v6/stan-dominique.png',
			},
		},
		nicolecrispi: {
			full_name: 'Nicole Crispi',
			pageContent: {
				heroHeader: 'The All-in-One Creator Store for bakers',
				heroSubHeader:
					'The easiest way for bakers to sell digital products, build a brand, and make money online without needing to be a tech wiz',
				referralComment:
					// eslint-disable-next-line
					'“I turned my baking passion into a stream of income with Stan. If you’re a baker and ready to make money online, Stan store is the place to start!”',
				headshot: '/images/home/v6/nicole-crispi-headshot.png',
				heroImage: '/images/home/v6/nicole-crispi-hero.png',
				// eslint-disable-next-line
				stanXcreatorHeader: "Exclusive Offer for Nicole Crispi's Community!",
				stanXcreatorSubheader:
					// eslint-disable-next-line
					"Nicole's community gets 30 days free. Claim this limited offer now.",
				stanXcreatorImage: '/images/home/v6/stan-nicole.png',
			},
		},
		thealyssastalker:{
			'full_name': 'Alyssa Stalker',
			'pageContent': {
			  'heroHeader': 'Your All-in-One Creator Store for Real Estate & Beyond',
			  'heroSubHeader': 'The ultimate link-in-bio that turns your audience into clients. Sell courses, digital products, book calls, and generate leads—all in one place with Stan Store.',
			  'referralComment':
			  // eslint-disable-next-line
			  "“I scaled my real estate business and boosted conversions effortlessly with Stan Store. It's the simplest, fastest, and highest-converting tool for creators and realtors looking to monetize and streamline their brand.”",
			  'headshot': '/images/home/v6/alyssa-stalker-headshot.png',
			  'heroImage': '/images/home/v6/alyssa-stalker-hero.png',
			  // eslint-disable-next-line
			  'stanXcreatorHeader': "Exclusive Offer for Alyssa Stalker's Community!",
			  'stanXcreatorSubheader':
			  // eslint-disable-next-line
			  "Alyssa's community gets 30 days free! Claim this limited offer and start converting your audience into paying clients today.",
			  'stanXcreatorImage': '/images/home/v6/stanXalyssa.png'
			}
		  },		
		  gabebult:{
			'full_name': 'Gabe Bult',
			'pageContent': {
			  'heroHeader': 'Your Favorite All-in-One Creator Store',
			  'heroSubHeader': 'Gabe’s audience gets their first month free when joining Stan. Start your business today.',
			  'referralComment':
			  // eslint-disable-next-line
			  "“Stan changed the game for me. It streamlined my business, making everything faster, simpler, and more affordable. If you want to build online without the headache, this is it.”",
			  'headshot': '/images/home/v6/gabe-headshot.png',
			  'heroImage': '/images/home/v6/gabe-hero.png',
			  // eslint-disable-next-line
			  'stanXcreatorHeader': "Exclusive Offer forGabe Bult's Community!",
			  'stanXcreatorSubheader':
			  // eslint-disable-next-line
			  "Gabe’s community gets their first month free when joining Stan. Start your business today.",
			  'stanXcreatorImage': '/images/home/v6/stanXgabe.png'
			}
		  }
	},
}

export default CONSTANTS
