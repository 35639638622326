import { defineStore } from 'pinia'
import axios from 'axios'
import CONSTANTS from '@/helpers/constants'

export const useLandingPage = defineStore('auth', {
	state: () => ({
		_referrerUserName: '',
		_trialDays: '',
		_referrer: {},
		_storeData: {},
		_customReferralPage: false,
		_customAd: false,
		_pageContent: {},
	}),
	getters: {
		referrer(state) {
			return state._referrer
		},
		pageContent(state) {
			return state._pageContent
		},
		referrerFirstName(state) {
			return this.hasReferrer ? state._referrer.user?.full_name?.split(' ')?.[0] : ''
		},
		referrerFullName(state) {
			return this.hasReferrer ? state._referrer?.user?.full_name : ''
		},
		hasReferrer: state => state._customReferralPage,
		hasAd: state => state._customAd,
		trialDays: state => state._trialDays,
		forceExperiment: state => state._customReferralPage || state._customAd,
	},
	actions: {
		async _initializeReferrer() {
			const urlString = window.location.href
			const url = new URL(urlString)
			this._referrerUserName = url.searchParams.get('ref') || ''
			//TODO: hardcoded case
			const isCustomCase = this._fakeProfile()
			this.hasReferrer && !isCustomCase && (await this._fetchReferrer())
		},
		_initalizeAdCampaign() {
			const urlString = window.location.href
			const url = new URL(urlString)
			const utmMedium = url.searchParams.get('utm_medium')
			const utmCampaign = url.searchParams.get('utm_campaign')
			return this._fetchAdContent(utmMedium, utmCampaign)
		},
		// returns if the ad is supported
		_fetchAdContent(utmMedium, utmCampaign) {
			if (!CONSTANTS.AD_CONTENT[utmMedium]) return false
			this._customAd = true
			const campaign = CONSTANTS.AD_CONTENT[utmMedium].campaign[utmCampaign]
			this._pageContent = campaign ? campaign.pageContent : CONSTANTS.AD_CONTENT[utmMedium].campaign.defaultStanCampaign.pageContent
			return true
		},
		_initializeTrialDays() {
			const urlString = window.location.href
			const url = new URL(urlString)
			if (!this._trialDays?.length) this._trialDays = url.searchParams.get('trialDays') || ''
		},
		async initialize() {
			const isAd = this._initalizeAdCampaign()
			!isAd && (await this._initializeReferrer())
			this._initializeTrialDays()
		},
		_fakeProfile() {
			const referrerUserNameLower = this._referrerUserName.toLowerCase()
			if (!CONSTANTS.FAKE_PROFILES[referrerUserNameLower]) return false
			this._referrer = {
				user: { full_name: CONSTANTS.FAKE_PROFILES[referrerUserNameLower].full_name },
			}
			this._pageContent = CONSTANTS.FAKE_PROFILES[referrerUserNameLower].pageContent
			this._trialDays = '30'
			this._customReferralPage = true
			return true
		},
		_cleanProfile() {
			this._referrerUserName = ''
			this._referrer = {}
			this._trialDays = ''
			this._customReferralPage = false
		},
		async _fetchReferrer() {
			try {
				const { data: response } = await axios.get(`v1/users/${this._referrerUserName}`)
				this._referrer.user = response.user
				this._storeData = response.storeData
			} catch (error) {
				this._cleanProfile()
			}
		},
	},
})
