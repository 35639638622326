import { useCookies } from 'vue3-cookies'
import { useFlowControlsStore } from '@/stores/flowControls'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import axios from 'axios'

const { cookies } = useCookies()

export const UUID = function () {
	const lut = []
	for (let i = 0; i < 256; i++) {
		lut[i] = (i < 16 ? '0' : '') + i.toString(16)
	}
	const d0 = (Math.random() * 0xffffffff) | 0
	const d1 = (Math.random() * 0xffffffff) | 0
	const d2 = (Math.random() * 0xffffffff) | 0
	const d3 = (Math.random() * 0xffffffff) | 0
	return `${lut[d0 & 0xff] + lut[(d0 >> 8) & 0xff] + lut[(d0 >> 16) & 0xff] + lut[(d0 >> 24) & 0xff]}-${lut[d1 & 0xff]}${lut[(d1 >> 8) & 0xff]}-${
		lut[((d1 >> 16) & 0x0f) | 0x40]
	}${lut[(d1 >> 24) & 0xff]}-${lut[(d2 & 0x3f) | 0x80]}${lut[(d2 >> 8) & 0xff]}-${lut[(d2 >> 16) & 0xff]}${lut[(d2 >> 24) & 0xff]}${
		lut[d3 & 0xff]
	}${lut[(d3 >> 8) & 0xff]}${lut[(d3 >> 16) & 0xff]}${lut[(d3 >> 24) & 0xff]}`
}

export const getSessionUUID = () => {
	// We save session for 10 minutes and renew every time there's an event to be tracked
	const sessionUuid = cookies.get('session_uuid') || UUID()
	cookies.set('session_uuid', sessionUuid, '10min')
	cookies.set('session_uuid', sessionUuid, '10min', null, 'stanwith.me')
	cookies.set('session_uuid', sessionUuid, '10min', null, 'stan.store')
	return sessionUuid
}

export const getCookieUUID = () => {
	const cookieUuid = cookies.get('cookie_uuid') || UUID()
	cookies.set('cookie_uuid', cookieUuid, '1y')
	cookies.set('cookie_uuid', cookieUuid, '1y', null, 'stanwith.me')
	cookies.set('cookie_uuid', cookieUuid, '1y', null, 'stan.store')
	return cookieUuid
}

export const fingerprint = async () => {
	const fpPromise = FingerprintJS.load({
		monitoring: false,
	})
	const fp = await fpPromise
	const result = await fp.get()

	// Removing canvas and plugins out of components list.
	const { canvas, plugins, ...components } = result.components

	// console.log(FingerprintJS.componentsToDebugString(components))
	const visitorId = FingerprintJS.hashComponents(components)

	return visitorId
}

export const getVisitorID = async () => {
	const visitorId = cookies.get('visitor_id') || (await fingerprint())

	// js-cookies uses days as base unit
	cookies.set('visitor_id', visitorId, '365d')
	cookies.set('visitor_id', visitorId, '365d', null, 'stanwith.me')
	cookies.set('visitor_id', visitorId, '365d', null, 'stan.store')
	return visitorId
}

export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

function isObject(value) {
	const type = typeof value
	return value != null && (type === 'object' || type === 'function')
}

export function lodashdebounce(func, wait, options) {
	console.log('todo.debounce wait: ', wait)
	let FUNC_ERROR_TEXT = 'Expected a function'
	let lastArgs,
		lastThis,
		maxWait,
		result,
		timerId,
		lastCallTime,
		lastInvokeTime = 0,
		leading = false,
		maxing = false,
		trailing = true

	if (typeof func !== 'function') {
		throw new TypeError(FUNC_ERROR_TEXT)
	}

	wait = +wait || 0

	if (isObject(options)) {
		leading = !!options.leading
		maxing = 'maxWait' in options
		maxWait = maxing ? Math.max(parseInt(options.maxWait) || 0, wait) : maxWait
		trailing = 'trailing' in options ? !!options.trailing : trailing
	}

	function invokeFunc(time) {
		var args = lastArgs,
			thisArg = lastThis

		lastArgs = lastThis = undefined
		lastInvokeTime = time
		result = func.apply(thisArg, args)
		return result
	}

	function leadingEdge(time) {
		// Reset any `maxWait` timer.
		lastInvokeTime = time
		// Start the timer for the trailing edge.
		timerId = setTimeout(timerExpired, wait)
		// Invoke the leading edge.
		return leading ? invokeFunc(time) : result
	}

	function remainingWait(time) {
		var timeSinceLastCall = time - lastCallTime,
			timeSinceLastInvoke = time - lastInvokeTime,
			timeWaiting = wait - timeSinceLastCall

		return maxing ? Math.min(timeWaiting, maxWait - timeSinceLastInvoke) : timeWaiting
	}

	function shouldInvoke(time) {
		var timeSinceLastCall = time - lastCallTime,
			timeSinceLastInvoke = time - lastInvokeTime

		// Either this is the first call, activity has stopped and we're at the
		// trailing edge, the system time has gone backwards and we're treating
		// it as the trailing edge, or we've hit the `maxWait` limit.
		return lastCallTime === undefined || timeSinceLastCall >= wait || timeSinceLastCall < 0 || (maxing && timeSinceLastInvoke >= maxWait)
	}

	function timerExpired() {
		var time = Date.now()
		if (shouldInvoke(time)) {
			return trailingEdge(time)
		}
		// Restart the timer.
		timerId = setTimeout(timerExpired, remainingWait(time))
	}

	function trailingEdge(time) {
		timerId = undefined

		// Only invoke if we have `lastArgs` which means `func` has been
		// debounced at least once.
		if (trailing && lastArgs) {
			return invokeFunc(time)
		}
		lastArgs = lastThis = undefined
		return result
	}

	function cancel() {
		if (timerId !== undefined) {
			clearTimeout(timerId)
		}
		lastInvokeTime = 0
		lastArgs = lastCallTime = lastThis = timerId = undefined
	}

	function flush() {
		return timerId === undefined ? result : trailingEdge(now())
	}

	function debounced() {
		var time = Date.now(),
			isInvoking = shouldInvoke(time)

		lastArgs = arguments
		lastThis = this
		lastCallTime = time

		if (isInvoking) {
			if (timerId === undefined) {
				return leadingEdge(lastCallTime)
			}
			if (maxing) {
				// Handle invocations in a tight loop.
				clearTimeout(timerId)
				timerId = setTimeout(timerExpired, wait)
				return invokeFunc(lastCallTime)
			}
		}
		if (timerId === undefined) {
			timerId = setTimeout(timerExpired, wait)
		}
		return result
	}

	debounced.cancel = cancel
	debounced.flush = flush
	return debounced
}

export function debounce(func, wait) {
	let timer
	return (...args) => {
		if (!timer) {
			func.apply(this, args)
		}
		clearTimeout(timer)
		timer = setTimeout(() => {
			timer = undefined
		}, wait)
	}
}

export const xdebounce = (func, wait) => {
	let timeout
	return function executedFunction(...args) {
		const later = () => {
			timeout = null
			func(...args)
		}
		clearTimeout(timeout)
		timeout = setTimeout(later, wait)
	}
}

export const requestIdleCallback =
	window.requestIdleCallback ||
	function (cb) {
		const start = Date.now()
		return setTimeout(() => {
			cb({
				didTimeout: false,
				timeRemaining() {
					return Math.max(0, 50 - (Date.now() - start))
				},
			})
		}, 1)
	}

export const sendAnalytics = async (route, cookies, stanAnalytics, pageVersion) => {
	const urlString = window.location.href
	const url = new URL(urlString)
	const param = url.searchParams.get('ref')

	if (param !== '' && param !== null && param !== undefined) {
		cookies.set('ref', param)
		cookies.set('ref', param, '7d', null, 'stanwith.me')
		cookies.set('ref', param, '7d', null, 'stan.store')
		stanAnalytics('referral-link-landing', { meta: { username: param } })
	}

	const referrer = window.document.referrer

	function urlDomain(url) {
		if (!referrer) {
			return
		}
		const a = document.createElement('a')
		a.href = url
		return a.hostname
	}

	const trialDays = url.searchParams.get('trialDays')
	if (trialDays !== '' && trialDays !== null && trialDays !== undefined) {
		cookies.set('trial_days', trialDays)
		cookies.set('trial_days', trialDays, '7d', null, 'stanwith.me')
		cookies.set('trial_days', trialDays, '7d', null, 'stan.store')
	}

	const promo = url.searchParams.get('promo')
	if (promo !== '' && promo !== null && promo !== undefined) {
		cookies.set('show_promo', true)
		cookies.set('show_promo', true, '7d', null, 'stanwith.me')
		cookies.set('show_promo', true, '7d', null, 'stan.store')
	}

	const annual = url.searchParams.get('annual')
	if (annual !== '' && annual !== null && annual !== undefined) {
		cookies.set('show_annual', true)
		cookies.set('show_annual', true, '7d', null, 'stanwith.me')
		cookies.set('show_annual', true, '7d', null, 'stan.store')
	}

	const monthly = url.searchParams.get('monthly')
	if (monthly !== '' && monthly !== null && monthly !== undefined) {
		cookies.set('show_monthly', true)
		cookies.set('show_monthly', true, '7d', null, 'stanwith.me')
		cookies.set('show_monthly', true, '7d', null, 'stan.store')
	}

	const queryParams = route.query
	const utmSource = queryParams.utm_source || urlDomain(referrer)
	if (utmSource) {
		cookies.set('utm_source', utmSource, '7d')
		cookies.set('utm_source', utmSource, '7d', null, 'stanwith.me')
		cookies.set('utm_source', utmSource, '7d', null, 'stan.store')
	}
	const utmMedium = queryParams.utm_medium
	if (utmMedium) {
		cookies.set('utm_medium', utmMedium, '7d')
		cookies.set('utm_medium', utmMedium, '7d', null, 'stanwith.me')
		cookies.set('utm_medium', utmMedium, '7d', null, 'stan.store')
	}
	const utmCampaign = queryParams.utm_campaign
	if (utmCampaign) {
		cookies.set('utm_campaign', utmCampaign, '7d')
		cookies.set('utm_campaign', utmCampaign, '7d', null, 'stanwith.me')
		cookies.set('utm_campaign', utmCampaign, '7d', null, 'stan.store')
	}
	const r = queryParams.referrer

	const fcStore = useFlowControlsStore()

	if (!fcStore.ipAddress) {
		try {
			await axios.get('v1/get-ip').then(response => {
				fcStore.$patch({ ipAddress: response.data.ip })
			})
		} catch {
			console.log('Axios Fetch Error')
		}
	}

	stanAnalytics('landing-page-view', {
		meta: { username: 'guest', ip: fcStore.ipAddress },
		props: { utmSource, utmMedium, utmCampaign, pageVersion },
		referrer: r,
	})
}

export const preloadImages = (images, complete) => {
	let loadedCounter = 0
	const toBeLoadedNumber = images.length

	function preloadImage(url, anImageLoadedCallback) {
		const preImg = document.createElement('link')
		preImg.href = url
		preImg.rel = 'preload'
		preImg.as = 'image'
		document.head.appendChild(preImg)
		preImg.onload = () => {
			anImageLoadedCallback()
		}
	}

	images.forEach(image => {
		preloadImage(image, () => {
			loadedCounter++
			if (loadedCounter === toBeLoadedNumber) {
				complete()
			}
		})
	})
}

export const imageLazyLoad = (areaId, lazyLoadClass) => {
	const lazyloadImages = document.querySelectorAll(`.${lazyLoadClass}`)
	if ('IntersectionObserver' in window) {
		const observerOptions = {
			root: document.getElementById(areaId),
			rootMargin: '200px',
			threshold: 0.1,
		}

		const imageObserver = new IntersectionObserver(entries => {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					const elm = entry.target
					if (elm.tagName === 'DIV' || elm.tagName === 'div') {
						if ('background' in elm.dataset) {
							elm.style['background-image'] = `url(${elm.dataset.background})`
						}
					} else if (elm.tagName === 'IMG') {
						elm.src = elm.dataset.src
					}
					elm.classList.remove(lazyLoadClass)
					elm.style.opacity = 1
					imageObserver.unobserve(elm)
				}
			})
		}, observerOptions)

		lazyloadImages.forEach(image => {
			imageObserver.observe(image)
		})
	} else {
		let lazyloadThrottleTimeout = null

		const lazyload = () => {
			if (lazyloadThrottleTimeout) {
				clearTimeout(lazyloadThrottleTimeout)
			}

			lazyloadThrottleTimeout = setTimeout(() => {
				const scrollTop = window.pageYOffset
				lazyloadImages.forEach(img => {
					if (img.offsetTop < window.innerHeight + scrollTop) {
						img.src = img.dataset.src
						img.style.opacity = 1
						img.classList.remove(lazyLoadClass)
					}
				})
				if (lazyloadImages.length === 0) {
					document.removeEventListener('scroll', lazyload)
					window.removeEventListener('resize', lazyload)
					window.removeEventListener('orientationChange', lazyload)
				}
			}, 20)
		}

		document.addEventListener('scroll', lazyload)
		window.addEventListener('resize', lazyload)
		window.addEventListener('orientationChange', lazyload)
	}
}
